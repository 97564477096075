












































import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import AbpBase from '@/lib/abpbase'
import PageRequest from '@/store/entities/page-request'
import DepartmentForm from './department-form.vue'
import SortingModel from "@/store/entities/sortingmodel";

class PageDepartmentRequest extends PageRequest {
    status: boolean | null;
}

@Component({
    components: {DepartmentForm}
})
export default class Department extends AbpBase {
    iIsVerifiedChange(val: string) {
        if (val === 'Yes') {
            this.pagerequest.status = true;
        } else if (val === 'No') {
            this.pagerequest.status = false;
        } else {
            this.pagerequest.status = null;
        }
    }

    edit() {
        this.editModalShow = true;
    }

    async changeSort(data) {
        if (this.pagerequest.sorting == null) {
            this.pagerequest.sorting = [];
        }

        let item = this.pagerequest.sorting.find(x => x.fieldName === data.key)
        if (item == null) {
            let sortingModel = new SortingModel();
            sortingModel.fieldName = data.key;
            sortingModel.isDescendingDirection = data.order === "desc";
            this.pagerequest.sorting.push(sortingModel)
        } else {
            item.isDescendingDirection = data.order === "desc";
        }
        await this.getpage()
    }

    //filters
    pagerequest: PageDepartmentRequest = new PageDepartmentRequest();

    createModalShow: boolean = false;
    editModalShow: boolean = false;

    get list() {
        return this.$store.state.department.list;
    };

    get loading() {
        return this.$store.state.department.loading;
    }

    create() {
        this.createModalShow = true;
    }

    pageChange(page: number) {
        this.$store.commit('department/setCurrentPage', page);
        this.getpage();
    }

    pagesizeChange(pagesize: number) {
        this.$store.commit('department/setPageSize', pagesize);
        this.getpage();
    }

    async getpage() {

        this.pagerequest.maxResultCount = this.pageSize;
        this.pagerequest.skipCount = (this.currentPage - 1) * this.pageSize;

        await this.$store.dispatch({
            type: 'department/getAll',
            data: this.pagerequest
        })
    }

    get pageSize() {
        return this.$store.state.department.pageSize;
    }

    get totalCount() {
        return this.$store.state.department.totalCount;
    }

    get currentPage() {
        return this.$store.state.department.currentPage;
    }

    columns = [{
        title: this.L('Name'),
        key: 'name',
        sortable: 'custom'
    }, {
        title: this.L('Code'),
        key: 'code',
        sortable: 'custom'
    }, {
        title: this.L('Status'),
        key: 'status'
    }, {
        title: this.L('Actions'),
        key: 'Actions',
        width: 150,
        render: (h: any, params: any) => {
            return h('div', [
                h('Button', {
                    props: {
                        type: 'primary',
                        size: 'small'
                    },
                    style: {
                        marginRight: '5px'
                    },
                    on: {
                        click: async () => {
                            this.$store.commit('department/edit', await this.$store.dispatch({
                                type: 'department/get',
                                data: params.row.id
                            }));
                            this.edit();
                        }
                    }
                }, this.L('Edit')),
                h('Button', {
                    props: {
                        type: 'error',
                        size: 'small'
                    },
                    on: {
                        click: async () => {
                            this.$Modal.confirm({
                                title: this.L('Tips'),
                                content: this.L('Delete Department Confirm'),
                                okText: this.L('Yes'),
                                cancelText: this.L('No'),
                                onOk: async () => {
                                    await this.$store.dispatch({
                                        type: 'department/delete',
                                        data: params.row
                                    })
                                    await this.getpage();
                                }
                            })
                        }
                    }
                }, this.L('Delete'))
            ])
        }
    }]

    async created() {
        await this.getpage();
    }
}
